<template>
  <div>
    <v-card-title v-once class="mt-n5 page-title-sub card-title">
      <v-row style="background-color: #F8F8F8">
        <v-col :cols="10" class="pb-0">
          <h1 class="mx-6 my-4 page-title-list" style="text-align: left;">
            自動メール
          </h1>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card class="mx-10 mt-2">
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
          class: 'custom-table elevation-1',
        }"
        ref="table"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :items="emailTemplateListMail"
        :disableSort="true"
      >
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn
            :disabled="!checkPerUser"
            @click="checkPerUser ? getContentMail(item.key) : ''"
            class="t-btn--prm"
            :style="{
              backgroundColor: !checkPerUser
                ? 'rgba(0, 0, 0, 0.12) !important'
                : '',
            }"
            icon
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
    <mail-information
      :key="idx"
      v-if="checkPerUser"
      :visible="visible === 1"
      :keyMail="keyMail"
      @close="visible = -1"
    ></mail-information>
  </div>
</template>
<script>
import mailInformation from './mailInformation.vue';
import gql from 'graphql-tag';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import Table from '@/components/Table/index.vue';

export default {
  components: {
    mailInformation,
    Table,
  },
  data() {
    return {
      idx: 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      keyMail: null,
      // Table
      headers: [
        {
          text: '詳細',
          align: 'center',
          value: 'detail',
          width: '100px',
        },
        {
          text: 'ID',
          value: 'orderId',
          width: '50px',
        },
        {
          text: '大分類',
          value: 'category1',
          width: '130px',
        },
        {
          text: '中分類',
          value: 'category2',
          width: '250px',
        },
        {
          text: '小分類',
          value: 'category3',
          width: '250px',
        },
        {
          text: 'メールタイトル',
          value: 'subject',
          width: '400px',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['emailTemplateListMail', 'contentMail']),
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['getEmailTemplateListMail']),

    ...mapMutations([
      'setCopyErrorText',
      'setEmailTemplateListMail',
      'setContentMail',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    async getContentMail(key) {
      this.idx = this.idx + 1;
      const query = `query (
            $key: String!
          ) {
            emailTemplate(key: $key) {
              subject 
              body 
              remarks 
            }
          }
          `;
      const variables = {
        key: key,
      };
      await this.$apollo
        .query({
          query: gql`
            ${query}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.setContentMail(data.data.emailTemplate);
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
      this.visible = 1;
      this.keyMail = key;
    },
  },
  mounted() {
    this.getEmailTemplateListMail();
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-data-table-header {
    tr {
      th {
        font-size: 14px !important;
        color: #757575 !important;
        font-weight: bold !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 16px !important;
        color: #757575 !important;
        font-weight: 400 !important;
      }
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    color: #000000;
    font-weight: 500;
    .v-select__selection--comma {
      font-size: 13px !important;
      color: #000000;
      font-weight: 500;
    }
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-table-header__icon {
    color: #000 !important;
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
</style>
