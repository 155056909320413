var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',{staticClass:"mx-10 mt-2"},[_c('Table',{ref:"table",attrs:{"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
        class: 'custom-table elevation-1',
      },"itemsPerPage":10,"itemsPerPageOptions":[10, 20, 50, 100, 200, 500],"headers":_vm.headers,"items":_vm.emailTemplateListMail,"disableSort":true},scopedSlots:_vm._u([{key:"item.detail",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",style:({
            backgroundColor: !_vm.checkPerUser
              ? 'rgba(0, 0, 0, 0.12) !important'
              : '',
          }),attrs:{"disabled":!_vm.checkPerUser,"icon":""},on:{"click":function($event){_vm.checkPerUser ? _vm.getContentMail(item.key) : ''}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1),(_vm.checkPerUser)?_c('mail-information',{key:_vm.idx,attrs:{"visible":_vm.visible === 1,"keyMail":_vm.keyMail},on:{"close":function($event){_vm.visible = -1}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',{staticClass:"mt-n5 page-title-sub card-title"},[_c('v-row',{staticStyle:{"background-color":"#F8F8F8"}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":10}},[_c('h1',{staticClass:"mx-6 my-4 page-title-list",staticStyle:{"text-align":"left"}},[_vm._v(" 自動メール ")])])],1)],1)}]

export { render, staticRenderFns }