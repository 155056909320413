<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <!-- <div class="justify-space-between d-flex">
          <h1 class="mb-3 dialog-title">[東急バケーションズ] 資料請求のご案内</h1>        
        </div> -->
        <v-row>
          <v-col>
            <div>
              <v-text-field
                label="メールタイトル"
                align-content="start"
                color="#000000"
                dense
                class="email-title-input"
                v-model="contentMail.subject"
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex" style="justify-content: right;">
              <v-btn large class="t-btn--red-dark mr-2" @click="closeDialog">
                <v-icon color="#FFFFFF" size="20"> mdi-close </v-icon>
                <span class="btn-text">閉じる</span>
              </v-btn>
              <v-btn large color="#1873D9" @click="save">
                <span class="btn-text">保存</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div class="mt-5">
          <div>
            <h5 class="custom-h5">メール本文</h5>
            <v-textarea
              outlined
              name="input-7-4"
              height="500"
              color="#000000"
              class="email-content-input"
              v-model="contentMail.body"
            ></v-textarea>
          </div>
          <div>
            <h5 class="custom-h5">備考</h5>
            <v-textarea
              outlined
              name="input-7-4"
              color="#000000"
              class="email-content-input"
              v-model="contentMail.remarks"
              :rules="[$rules.checkTextAreaLength()]"
            ></v-textarea>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'mailInformation',
  data() {
    return {};
  },
  props: {
    visible: Boolean,
    keyMail: String
  },
  computed: {
    ...mapGetters(['contentMail']),

    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      'setAlertSuccess',
      'setContentMail'
    ]),

    ...mapActions([
      'updateEmailTemplate'
    ]),

    save() {
      this.updateEmailTemplate(this.keyMail)
      this.$emit('close');
    },
    closeDialog() {
      // this.setContentMail({
      //   subject: '',
      //   body: '',
      //   remarks: ''
      // })
      // this.$refs.form.reset()
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
// -- Dialog --
body {
  overflow: hidden;
}
.btn-text {
  font-size: 14px;
  color: #FFFFFF;
}
.dialog-title {
  font-size: 20px;
  color: #000000;
}
::v-deep {
  .v-dialog {
    width: 1500px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
// -- End Dialog --
.custom-h5 {
  font-size: 10px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px;
}
.div-content-custom {
  border: 1px solid black;
  height: auto;
  border-radius: 10px;
  padding: 10px 20px;
}
.div-remarks-custom {
  border: 1px solid black;
  height: auto;
  border-radius: 10px;
  padding: 10px 20px;
}
</style>

<style lang="scss">
.email-title-input {
  input {
    color: #000000 !important;
    font-size: 12px !important;
  }
  .v-label {
    font-size: 14px !important;
  }
  .v-label--active {
    font-weight: bold;
    color: #000000;
  }
}
.email-content-input {
  textarea {
    color: #000000 !important;
    font-size: 14px !important;
  }
}
</style>
